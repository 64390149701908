<template>
  <div class="table-responsive">
    <div class="top-bar d-flex align-center mb-3">
      <a-tooltip
        placement="top"
        class="pr-0"
        mouse-leave-delay="0.5"
      >
        <template #title>
          Create New
        </template>
        <button
          class="btn btn-primary"
          @click="add = true"
        >
          Create New
        </button>
      </a-tooltip>
    </div>
    <table class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>SBC Name</th>
          <!--          <th>Protocol</th>-->
          <th>SBC IPs</th>
          <th>Status</th>
          <th>Created On</th>
          <th width="120px">
            Action
          </th>
        </tr>
      </thead>
      <tbody v-if="items">
        <tr v-if="add">
          <td>
            <a-input v-model:value="newItem.name" />
          </td>
          <!-- <td>{{ item.protocol }}</td> -->
          <td>
            <vue-ip-input
              :ip="newItem.ip"
              :on-change="onIpChange"
            />
            <div
              v-if="ipMessage !== ''"
              style="color: red"
            >
              {{ ipMessage }}
            </div>
          </td>
          <!-- <td>item</td> -->
          <td />
          <!-- <td>{{ getDate(item.created_on) }}</td> -->
          <td class="d-flex">
            <a-tooltip
              placement="top"
              class="pr-0"
              mouse-leave-delay="0.5"
            >
              <template #title>
                Save
              </template>
              <a
                class="a_action_icon mb-2 mr-2"
                @click="addNewItem"
              >
                <i
                  class="fa fa-floppy-o"
                  aria-hidden="true"
                />
              </a>
            </a-tooltip>

            <a-tooltip
              placement="top"
              class="pr-0"
              mouse-leave-delay="0.5"
            >
              <template #title>
                Cancel
              </template>
              <a
                class="a_action_icon mb-2 mr-2"
                @click="add = false"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </a-tooltip>
          </td>
        </tr>
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>
            <template v-if="!item.edit">
              {{ item.name }}
            </template>
            <a-input
              v-else
              v-model:value="editItem.name"
            />
          </td>
          <!--          <td>{{ item.protocol }}</td>-->
          <td>
            <template v-if="!item.edit">
              {{ item.ips.length ? item.ips[0].ip : '' }}
            </template>
            <vue-ip-input
              v-else
              :ip="((editItem).ip !== undefined) && (editItem.ip !== null) ? editItem.ip : ''"
              @change="onIpChange"
            />
          </td>
          <td>{{ item.active ? 'Active' : 'Inactive' }}</td>
          <td>{{ getDate(item.created_on) }}</td>
          <td class="d-flex">
            <template v-if="!item.edit">
              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Edit
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="handleEdit(item)"
                >
                  <i
                    class="fa fa-pencil"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>

              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Inactive
                </template>
                <a
                  v-if="!item.active"
                  class="a_action_icon bg-danger mb-2 mr-2"
                  @click="updateActivity(item)"
                >
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>

              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Active
                </template>
                <a
                  v-if="item.active"
                  class="a_action_icon bg-success mb-2 mr-2"
                  @click="updateActivity(item)"
                >
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>

              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Delete
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="deleteItem(item)"
                >
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </template>
            <template v-else>
              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Save
                </template>
                <a
                  class="a_action_icon mb-2 mr-2"
                  @click="handleSaveItem(item)"
                >
                  <i
                    class="fa fa-floppy-o"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
              <a-tooltip
                placement="top"
                class="pr-0"
                mouse-leave-delay="0.5"
              >
                <template #title>
                  Cancel
                </template>
                <a
                  class="a_action_icon mb-2 mr-2"
                  @click="item.edit = false"
                >
                  <i
                    class="	fa fa-times"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <a-pagination
      v-if="total"
      class="pagination-right"
      :default-current="1"
      :total="total"
      :show-less-items="true"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
import VueIpInput from 'vue-ip-input'
import axios from '@/axios'
import moment from '@/plugins/moment'
import {Modal, notification} from 'ant-design-vue'

export default {
  components: {
    'vue-ip-input': VueIpInput
  },
  data() {
    return {
      port: '',
      items: null,
      step: 10,
      skip: 0,
      add: false,
      edit: false,
      ipMessage: '',
      newItem: {
        name: null,
        ip: '',
      },
      editItem: {
        name: null,
        ip: null,
      }
    }
  },
  computed: {
    // validIP() {
    //   if (value.length === 0 || value.length > 511) {
    //     return 'Address length must be between 1 and 511 characters.'
    //   }
    //
    //   var regExpIp = new RegExp(
    //     '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
    //   )
    //   var regResultIp = regExpIp.exec(value)
    //   var regExpHostname = new RegExp(
    //     /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
    //   ) // RFC 1123
    //   var regResultHostname = regExpHostname.exec(value)
    //   if (regResultIp === null && regResultHostname === null) {
    //     return 'Address must be a valid IP address or hostname.'
    //   }
    // },
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    onIpChange(ip) {
      this.newItem.ip = ip
    },
    handleEdit(item) {
      item.edit = true;
      this.editItem.name = item.name;
      this.editItem.ip = item.ips[0].ip;
    },
    handleSaveItem(item) {
      let param = {
        name: this.editItem.name,
        ips: [{ip: this.editItem.ip, active: true}],
      }
      axios
          .put(`/authentication/sbc/${item.id}`, param)
          .then(() => {})
          .catch(() => {}).finally(() => {
        item.edit = false
        this.loadItems()
      })
    },
    addNewItem() {
      const params = {
        name: this.newItem.name,
        ips: [{ip: this.newItem.ip, active: true}],
        active: true
      };
      if(this.newItem.ip === '') {
        this.ipMessage = 'SBC IPS should not be empty'
        return false
      }
      axios
          .post('/authentication/sbc', params)
          .then(() => {
            notification.success({
              message: 'Successfully',
            })
            this.newItem.name = ''
            this.newItem.ip = ''
          }).catch(() => {}).finally(() => {
        this.add = false
        this.loadItems()
      })
    },
    loadItems() {
      axios
          .get(`/authentication/sbc?order_dir=DESC&order_by=created_on&take=10&skip=${this.skip}`)
          .then((res) => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count : res.data.count
          })
          .catch(() => {})
    },
    updateActivity(item) {
      const vm = this
      Modal.confirm({
        title: `Do you Want to ${item.active ? 'Inactivate' : 'Activate'} this item?`,
        onOk() {
          item.active = !item.active
          axios
              .put(`/authentication/sbc/${item.id}`, item)
              .then((res) => {
                if(res.data.active === true) {
                  notification.success({
                    message: 'Your SB has been activated',
                  })
                }else {
                  notification.success({
                    message: 'Your SB has been deactivated',
                  })
                }
              }).catch(() => {})
        },
        onCancel() {},
        class: 'test',
      })
    },
    getDate(date) {
      return moment(date)
    },
    deleteItem(item) {
      const vm = this
      Modal.confirm({
        title: 'Do you Want to delete this item?',
        onOk() {
          vm.deleteReq(item)
        },
        onCancel() {},
        class: 'test',
      })
    },
    deleteReq(item) {
      axios
          .delete(`/authentication/sbc/${item.id}`)
          .then(() => {
            this.$store.dispatch('deleted', item.name)
            this.loadItems()
          })
          .catch(() => {})
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    create() {
      axios
          .put(`/authentication/sbc/${this.id}`, this.item)
          .then((res) => {
            notification.success({
              message: 'Updated',
            })
            this.$router.push('/verif-whitelist')
          })
          .catch((err) => {
            notification.error({
              message: err.response.error,
              description: [...err.response.message],
            })
          })
    },
  },
}
</script>

<style>
.ip-input-container {
  display: inline-block;
  height: 32px!important;
  width: 100%!important;
  font-variant: tabular-nums!important;
  list-style: none!important;
  font-feature-settings: 'tnum' !important;
  position: relative!important;
  padding: 4px 11px!important;
  color: #595c97!important;
  font-size: 14px!important;
  line-height: 1.5715!important;
  background-color: #ffffff!important;
  background-image: none!important;
  border: 1px solid #e4e9f0!important;
  border-radius: 8px!important;
  transition: all 0.3s!important;
}
.ip-input-container:hover {
  border-color: #78a3ff!important;
  border-right-width: 1px !important;
}
</style>

<style scoped>
.a_action_icon > i {
  font-size: 18px;
  color: white;
  margin: 5px 7px;
}
.pagination-right {
  text-align: right !important;
  margin-right: 86px !important;
}
</style>
